









































import { Component, Vue } from 'vue-property-decorator'
import { getNavItems } from '@/helper/helper'

interface NavItem {
  name: string
  path: string
}
@Component
export default class Nav extends Vue {
  private get navItems(): NavItem[] {
    const anchorLinks: NavItem[] = [
      { name: 'Benefits', path: '/#benefits' },
      { name: 'UX', path: '/#ux' },
      { name: 'FAQs', path: '/#faq' },
      { name: 'Team', path: '/#team' },
      { name: 'Facts', path: '/#numbers' },
      { name: 'Contact', path: '/#footer' },
    ]
    const routerNavItems = getNavItems(this.$router, true)
    return [...anchorLinks, ...routerNavItems]
    // return getNavItems(this.$router, true)
  }
}
